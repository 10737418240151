.vh-fill {
    min-height: calc(100vh - 180px);
}

.vh-70 {
    min-height: 70vh;
}

.header {
    background-image: url('/public/images/faq_Header.jpg');
    background-size: cover;
}

.header-box {
    background-color: #fff;
}

.powur-btn-primary {
    background-color: #20C2F1;
    border: #20C2F1;
}

.powur-btn-primary:hover {
    background-color: #2CB8DF;
    border: #2CB8DF;
}

.powur-btn-secondary {
    background-color: #ffffff;
    border: 1px solid #20C2F1;
    color: #20C2F1;
}

.powur-btn-secondary:hover {
    background-color: #2CB8DF;
    border: 1px solid #2CB8DF;
}

.powur-link {
    color: #20C2F1 !important;
    text-decoration: none !important;
}

.bg-powur-blue {
    background-color: #20C2F1;
}

.accordion-button::after {
    text-align: center;
    margin: auto;
}
.alertmessage{
    height:40px;
}

.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 2fr 2fr 2fr 2fr 1fr;
}

@media (max-width:1280px) {
    .grid{
        display: grid;
        grid-template-columns:  3fr 3fr 3fr 2fr;;
        grid-template-rows: 4fr 4fr 4fr 4fr 4fr 4fr 4fr 4fr 2fr;
    }
}

@media (max-width:1024px) {
    .grid{
        display: grid;
        grid-template-columns: 3fr 3fr 3fr;
        grid-template-rows: 4fr 4fr 4fr 4fr 4fr 4fr 4fr 4fr 2fr;
    }
}

@media (max-width:768px) {
    .grid{
        display: grid;
        grid-template-columns: 3fr 3fr 3fr;
        grid-template-rows: 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 2fr;
    }
}

@media (max-width:425px) {
    .grid{
        display: grid;
        grid-template-columns: 3fr 3fr;
        grid-template-rows: 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr 5fr;
    }
}

.custom-link {
    text-align: center;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
}

.popover-title {
    text-align: center;
    background-color: #eee;
    padding: 5px 0;
    border-radius: 5px;
    font-weight: 700;
    margin-bottom: 1rem;
}
.nav-logo{
    max-width: 180px;
    margin-left: 0px !important;
}

nav a .nav-logo:hover {
    border-bottom: none !important;
}

nav a {
    color: #777;
    text-decoration: none;
    padding-bottom: 10px;
    margin: 10px 0px 3px 20px;
    font-weight: 400;
}

a.dropdown-item{
    background-color: white !important;
}

nav a.active, .navLink:hover {
    border-bottom: 2px solid #2CB8DF;
    color: #777;
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    border-bottom: none !important;
}

.dropdown-menu {
    padding-right: 2rem;
    padding-left: 0px;
}

.dropdown-item:focus, .dropdown-item:hover {
    border-bottom: none;
    margin-bottom: 3px;
}